import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  calendar,
  contacts,
  hammer,
  help,
  logIn,
  logOut,
  map,
  person,
  personAdd,
  informationCircle,
  journal
} from 'ionicons/icons';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {RootState} from '../store';

const routes = {
  appPages: [
    {title: '首頁', path: '/root', icon: calendar, iconlink: '/assets/icon/home.svg'},
    {title: '對課自助管理', path: '/curriculumlist', icon: '', iconlink: '/assets/icon/open-books_b.svg'},
    {title: '下載逐字講稿', path: '/curriculumlist_tutorial', icon: '', iconlink: '/assets/icon/doc_b.svg'},
    {title: '工人補給站', path: '/articlelist_qptool', icon: informationCircle, iconlink: '/assets/icon/bulb_b.svg'},
    {title: '全球見證', path: '/articlelist', icon: informationCircle, iconlink: '/assets/icon/leaf_b.svg'},
    {title: '工人平台操作教學影片', path: '/articlecontent/1045', icon: '', iconlink: '/assets/icon/film_b.svg'},
    {title: '常見問題解答', path: '/qa', icon: informationCircle, iconlink: '/assets/icon/qa_b.svg'}
  ],
  loggedInPages: [
    {title: '個人資料及派工意願', path: '/personaldata', icon: '', iconlink: '/assets/icon/mur.svg'},
    {title: '隱私權及服務條款', path: '/termsprivacy', icon: '', iconlink: '/assets/icon/qa_b.svg'},
    // {title: '教材下載', path: '/teachingmaterial', icon: '', iconlink: '/assets/icon/downloadd.svg'},
    // {title: '語言', path: '/language', icon: '', iconlink: '/assets/icon/language.svg'},
    {title: '登出', path: '/logout', icon: '', iconlink: '/assets/icon/logout.svg'}
  ],
  loggedOutPages: [
    {title: '常見問題解答', path: '/qa', icon: '', iconlink: '/assets/icon/qa_b.svg'},
    {title: '隱私權及服務條款', path: '/termsprivacy', icon: '', iconlink: '/assets/icon/qa_b.svg'},
    {title: '登入', path: '/login', icon: logIn}
  ]
};

type Props = RouteComponentProps<{}> & ReturnType<typeof mapStateToProps>;


// (e) => {
//   e.preventDefault();
//   this.props.history.push(`/studentmanagement/${it.對象Id}`);
// }}


const Menu: React.SFC<Props> = ({isAuthenticated, history}) => {
  function renderlistItems(list: any[]) {
    return list
      //.filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem button onClick={(e) =>
          {
            e.preventDefault();
            history.push(p.path);
            //this.props.history.push(p.path);
          }}>
            <IonIcon slot="start" icon={p.icon} src={p.iconlink}/>
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu contentId="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle>選單</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="outer-content">
        <IonList>
          <IonListHeader>功能</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList>
          <IonListHeader>設定</IonListHeader>
          {isAuthenticated ? renderlistItems(routes.loggedOutPages) : renderlistItems(routes.loggedInPages)}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.user.isAuthenticated
});

export default withRouter(connect(mapStateToProps)(Menu));
